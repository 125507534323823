.divider:after,
.divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
}

.h-custom {
    height: calc(100% - 73px);
}

@media (max-width: 450px) {
    .h-custom {
        height: 100%;
    }
}

.edit_button{
    margin-left: 3px !important;

    font-size: 9.9px;
    color: blue !important;
}

.edit_button:hover {
    cursor: pointer;
    color: purple !important;
}
.errormsg{
    margin-bottom: 1rem;
    font-size: 12px;
    font-weight: 500;
    color: red;
    margin-top: 7px;
  }

